<template>
  <form @submit.prevent="save()">
    <div class="row">
      <div class="col-12 col-xl-8">
        <div class="form-group">
          <label for="name">Name</label>
          <input
            type="text"
            class="form-control"
            name="name"
            v-model.trim="form.name"
            required
          />
        </div>
      </div>
      <div class="col col-xl-2">
        <div class="form-group">
          <label for="nr">Studionummer</label>
          <input
            type="text"
            class="form-control"
            name="nr"
            v-model.number="form.nr"
            required
          />
        </div>
      </div>
      <div class="col col-xl-2">
        <div class="form-group">
          <label for="cost-centre">Kostenstelle</label>
          <input
            type="text"
            class="form-control"
            name="cost-centre"
            v-model.trim="form.costCentre"
          />
        </div>
      </div>
      <div class="col col-xl-8">
        <div class="form-group">
          <label for="cost-centre">Holding</label>
          <select class="form-control" v-model="form.holding">
            <option disabled :value="null">Holding auswählen...</option>
            <option v-for="holding in holdings" :key="holding.id">{{
              holding.name
            }}</option>
          </select>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-dark" :disabled="submitting">
      Speichern
    </button>
    <div class="alert alert-danger mt-3" v-if="errMsg">{{ errMsg }}</div>
  </form>
</template>

<script>
import { firestore } from '../firebase';

export default {
  data() {
    return {
      holdings: [],
      studio: null,
      form: {
        name: '',
        costCentre: '',
        nr: 0,
        holding: null
      },
      submitting: false,
      errMsg: ''
    };
  },
  async created() {
    const id = this.$route.params.id;

    const holdingsSnapshot = await firestore.collection('holdings').get();

    this.holdings = holdingsSnapshot.docs.map(doc => {
      return {
        id: doc.id,
        ...doc.data()
      };
    });

    if (id) {
      const studioSnapshot = await firestore
        .collection('studios')
        .doc(id)
        .get();

      this.studio = { id: studioSnapshot.id, ...studioSnapshot.data() };
      this.form = { ...this.studio };

      delete this.form.id;
    }
  },
  methods: {
    async save() {
      this.submitting = true;
      this.errMsg = '';

      try {
        if (this.studio) {
          await firestore
            .collection('studios')
            .doc(this.studio.id)
            .update({
              ...this.form
            });
        } else {
          await firestore.collection('studios').add({
            ...this.form
          });
        }

        this.$router.push({
          name: 'studios'
        });
      } catch (err) {
        this.errMsg = err.message;

        throw err;
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>
